(function ($) {
  if ($.fancybox) {
    $.fancybox.defaults.transitionEffect = "fade";
    $.fancybox.defaults.transitionDuration = "1500";

    $("[data-fancybox]").fancybox({
      loop: false,
      infobar: true,
      buttons: ["close"],
      infobar: true,
      zzom: false,
      caption: function () {
        return (
          $(this)
          .find(".caption")
          .html() || ""
        );
      }
    });
  }

  $('#header .toggler').click(function () {
    $(this).toggleClass('is-active');
    $('.navbar-collapse').toggleClass('show');
    $('body').toggleClass('no-scroll');
  });

  $('table').stacktable();



  // $("#workshops").imagesLoaded(function (instance) {
  //   console.log(this);
  //   $(this).find(".grid").isotope({
  //     itemSelector: ".grid-item",
  //     layoutMode: "fitRows",
  //     isInitLayout: true
  //   });
  // });


  function findBootstrapEnvironment() {
    var envs = ['xs', 'sm', 'md', 'lg', 'xl', 'lx', 'lm'];

    var el = document.createElement('div');
    document.body.appendChild(el);

    var curEnv = envs.shift();

    for (i in envs.reverse()) {
      el.classList.add('d-' + envs[i] + '-none');

      if (window.getComputedStyle(el).display === 'none') {
        curEnv = envs[i];
        break;
      }
    };

    document.body.removeChild(el);
    $('html').addClass('bs-' + curEnv);
    return curEnv;
  }

  findBootstrapEnvironment();

  
  /*=============================================
  =            FitVids            =
  =============================================*/

  $(document).ready(function(){
    // Target your .container, .wrapper, .post, etc.
    $(".video-wrapper").fitVids();
  });
  
  

  /*=============================================
  =            fixed scroll header            =
  =============================================*/
  $(window).scroll(function () {
    if ($(window).scrollTop() >= 100) {
      $('#header').addClass('fixed-header');
      $('#header div').addClass('visible-title');
    } else {
      $('header').removeClass('fixed-header');
      $('header div').removeClass('visible-title');
    }
  });
  


  /*=============================================
  =      External Links in new Window           =
  =============================================*/

  $('a[href^="http://"], a[href^="https://"]')
    .not('[href*="' + window.location.hostname + '"]')
    .attr("target", "_blank");

})(jQuery);
